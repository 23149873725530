<template>
  <div class="dateSelect-wrap">
    <div class="dateSelect-top">
      <div @click="openPicker" class="dateSelect-top-title">{{this.year}}年{{this.month}}月<span class="down"></span></div>
<!--      <el-button @click="returnToday" class="dateSelect-select day">重置</el-button>-->
    </div>

    <div class="dateSelect-content">
      <ul>
        <li v-for="(item,ind) in dateList" :key="item" :class="{'active': activeDate === item,'disable': item > dataAdd0(new Date(initDate))}" :style="{'left':   10 * ind  - 4 + 'vw' }">
          <span class="dateSelect-name">{{item.slice(8)}}</span>
        </li>
      </ul>
      <div class="dateSelect-left" @click="this.beforeDay"></div>
      <div :class="`dateSelect-right ${isDisabled ? 'disable' : ''}`" @click="this.afterDay"></div>
    </div>


    <mt-datetime-picker
        v-model="dates"
        ref="picker"
        type="date"
        :endDate="new Date()"
        class="datetime-picker"
        @confirm="selectChange"
    >
    </mt-datetime-picker>
<!--    year-format="{value} 年"-->
<!--    month-format="{value} 月"-->
<!--    date-format="{value} 日"-->

  </div>
</template>

<script>
export default {
  name: "dateSelect",
  data() {
    // let initDate = new Date().getTime() - (24 * 60 * 60 * 1000);
    let initDate = new Date().getTime();
    let yearLen = new Date().getFullYear() - new Date('2016').getFullYear() + 1;
    return {
      yearLen:yearLen,
      dateList: [],
      year: new Date(initDate).getFullYear(),
      month: new Date(initDate).getMonth() + 1,
      day: new Date(initDate).getDate(),
      monthLen: new Date(initDate).getMonth() + 1,
      leftMonthDisable: new Date(initDate).getMonth() === 0 ? true : false,
      rightMonthDisable: true,
      initDate: initDate,
      liLeft: -4,
      dates: new Date(),
      isShowPicker: false,
    }
  },
  computed: {
    activeDate() {
      let month = (this.month+ '').padStart(2,'0');
      let day = (this.day+ '').padStart(2,'0');
      if(this.year === new Date(this.initDate).getFullYear()){
        this.monthLen = new Date().getMonth() + 1;
      }else{
        this.monthLen = 12
      }
      this.leftMonthDisable = this.month == 1 ? true : false;
      this.rightMonthDisable = this.monthLen == this.month ? true : false;
      this.dates = new Date(this.year + '-' + month + '-' + day)
      return this.year + '-' + month + '-' + day;
    },
    isDisabled() {
      return this.dataAdd0(new Date(this.initDate)) == this.activeDate ? true : false;
    }
  },
  watch: {
    activeDate: function (val){
      // console.log(val,'date=====')
      this.$emit("dateChange",val)
    }
  },
  mounted() {
    this.getDateList(this.year,this.month,this.day)
    // this.$emit("dateChange",this.activeDate)
  },
  methods: {
    openPicker() {
      this.$refs.picker.open();
      this.isShowPicker = true;
    },
    closePicker() {
      this.$refs.picker.close();
      this.isShowPicker = false;
    },
    //问题类型的弹框picker值发生改变
    onValuesChange(picker, values) {
      this.popupVal = values[0];
    },
    //弹框点击确认
    selectChange(val) {
      console.log(val,'selectChange')
      this.$nextTick(()=>{
        this.year = new Date(val).getFullYear();
        this.month = new Date(val).getMonth() + 1;
        this.day = new Date(val).getDate();
        this.getDateList(this.year,this.month,this.day)
      })

    },
    //返回今天
    returnToday(){
      this.year = new Date(this.initDate).getFullYear();
      this.month = new Date(this.initDate).getMonth() + 1;
      this.day = new Date(this.initDate).getDate();
      this.getDateList(this.year,this.month,this.day)
    },
    //前一天
    beforeDay(){
      // this.dateList[0]
      var stime = new Date(this.dateList[0]).getTime() - (24 * 60 * 60 * 1000);
      // console.log(this.getDiffdate(this.dataAdd0(stime),this.dataAdd0(stime)))
      let beforeArr = this.getDiffdate(this.dataAdd0(stime),this.dataAdd0(stime));
      this.dateList.unshift(beforeArr[0])
      this.$nextTick(()=>{
        this.dateList.pop()
        this.year = this.dateList[4].split('-')[0] - 0
        this.month = this.dateList[4].split('-')[1] - 0
        this.day = this.dateList[4].split('-')[2] - 0;
        console.log(this.month,'------')
      })

      // console.log(this.dateList)
    },
    //后一天
    afterDay(){
      if(this.isDisabled) return false;
      var etime = new Date(this.dateList[this.dateList.length - 1]).getTime() + (24 * 60 * 60 * 1000);
      let endArr = this.getDiffdate(this.dataAdd0(etime),this.dataAdd0(etime));
      this.dateList.push(endArr[0])

      this.$nextTick(()=>{
        this.dateList.splice(0,1)
        this.year = this.dateList[4].split('-')[0] - 0
        this.month = this.dateList[4].split('-')[1] - 0
        this.day = this.dateList[4].split('-')[2] - 0
      })

    },

    //获取日期区间
    getDateList(year,month,day){
      year = year + '';
      month = (month+ '').padStart(2,'0');
      day = (day+ '').padStart(2,'0');
      let date = year + '-' + month + '-' + day;
      // console.log(date)
      var stime = new Date(date).getTime() - (24 * 60 * 60 * 1000 * 4); //开始日期
      var etime = new Date(date).getTime() + (24 * 60 * 60 * 1000 * 4); //结束日期
      // console.log(stime)
      // console.log(etime)
      this.dateList = this.getDiffdate(this.dataAdd0(stime),this.dataAdd0(etime))
    },
    //日期补0，传时间对象或时间戳
    dataAdd0(date){
      var year = new Date(date).getFullYear() + '-';
      var month = (new Date(date).getMonth() + 1 + '').padStart(2,'0') + '-';
      var day = (new Date(date).getDate() + '').padStart(2,'0');
      return year + month + day;
    },
    //获取两日期之间日期列表函数
    getDiffdate(stime, etime) {
      //初始化日期列表，数组
      var diffdate = new Array();
      var i = 0;
      //开始日期小于等于结束日期,并循环
      while (stime <= etime) {
        diffdate[i] = stime;
        //获取开始日期时间戳
        var stime_ts = new Date(stime).getTime();
        //增加一天时间戳后的日期
        var next_date = stime_ts + (24 * 60 * 60 * 1000);
        stime = this.dataAdd0(next_date);
        // console.log("times",stime)
        //增加数组key
        i++;
      }
      // console.log(diffdate);
      return diffdate;
    }
  }
}
</script>

<style scoped lang="scss">
.dateSelect-wrap{
  margin-bottom: 68px;
  .dateSelect-top{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    margin: 30px auto;
    .dateSelect-top-title{
      font-size: 26px;
      color: #232a24;
      display: flex;
      align-items: center;
      .down{
        margin-left: 8px;
        display: inline-block;
        width: 21px;
        height: 13px;
        background: url("../../assets/imgs/dateSelect/down.png");
        background-size: 21px 13px;
      }
    }
  }

  .dateSelect-content{
    margin: 30px auto 0;
    height: 44px;
    position: relative;
    ul{
      position: absolute;
      top: 0;
      left: 78px;
      width: 550px;
      height: 100%;
      display: flex;
      overflow: hidden;
      transition: all 0.3s;
      background: #d7ecff;
      border-radius: 44px;
      overflow: hidden;
      &> li:first-child{
        left: -4vw;
      }
      li{
        user-select: none;
        position: absolute;
        top: 0px;
        z-index: 3;
        text-align: center;
        color: #363636;
        font-size: 16px;
        line-height: 44px;
        transition: all 0.3s;
        .dateSelect-name{
          position: relative;
          z-index: 6;
        }
        &.active{
          color: #fff;
          &::before{
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background: #4f77e1;
            content: '';
            position: absolute;
            left: -13px;
            top: 0;
            z-index: 5;
          }
        }
        &.disable{
          color: #a0a0a0;
        }
      }
    }
    .dateSelect-left,.dateSelect-right{
      cursor: pointer;
      position: absolute;
      z-index: 5;
      top: 0px;
      left: 0px;
      width: 44px;
      height: 44px;
      background: url("../../assets/imgs/dateSelect/left.png") no-repeat ;
      background-size: 100% 100%;
    }
    .dateSelect-right{
      left: initial;
      right: 0px;
      transform: rotate(180deg);
      background: url("../../assets/imgs/dateSelect/left.png") no-repeat ;
      background-size: 100% 100%;
      &.disable{
        cursor: not-allowed;
        transform: rotate(0deg);
        background: url("../../assets/imgs/dateSelect/right.png") no-repeat ;
        background-size: 100% 100%;
      }
    }
  }

  .datetime-picker {
    width: 100vw;
    height: 40vh;
    &::v-deep .picker-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      line-height: 1;
      padding-top: 30px;
      padding-bottom: 10px;
      border-bottom: none;
      .mint-datetime-cancel {
        width: 32%;
        color: #232a24;
        font-size: 29px;
      }
      .mint-datetime-confirm {
        width: 32%;
        color: #1d67ff;
        font-size: 29px;
      }
    }
  }

}
</style>
