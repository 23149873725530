<template>
  <div class="public">
    <div class="container HomeSupervise">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box" v-for="item in forCodeData" :key="item.type">
          <div class="box-top">
            {{ item.name1 }}
            <div class="box-topnum color1">
              {{ item.val1 }}
              <div class="box-topcom">元</div>
            </div>
          </div>
          <div class="box-abso">
            <div class="box-abso-child" :style="{ width: item.val3 }"></div>
          </div>
          <div class="box-bottom">
            <div>
              {{ item.name2 }}
              <div>{{ item.val2 }}元</div>
            </div>
            <div>
              {{ item.name3 }}
              <div>{{ item.val3 }}</div>
            </div>
            <div>
              {{ item.name4 }}
              <div>{{ item.val4 }}元</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 监管资金占比情况 饼图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName }}分布情况</span>
          </div>
          <!-- <el-button @click="toOnePie()" v-show="isTowPie" type="text" style="position: absolute;top: 6px;right: 130px;font-size: 14px;">返回</el-button> -->
        </div>
        <div class="pie-subTitle">
          <!-- <label class="txt"
            >银行存管：<i>{{ cunguan + "%" }} </i></label
          >
          <label class="txt"
            >保证保险：<i>{{ baoxian + "%" }}</i></label
          > -->
        </div>
        <div class="tab-box">
          <el-radio-group v-model="tabPosition1" class="tab-btns">
            <el-radio-button label="1">资金</el-radio-button>
            <el-radio-button label="2">商户</el-radio-button>
          </el-radio-group>
        </div>

        <div class="clearfix pie-chart-box">
          <div class="clearfix-box">
            <div class="clearfix-box-child">
              <div class="clearfix">
                <div
                  class="txt"
                  v-for="item in statisticsData.slice(0, 1)"
                  :key="item.name"
                >
                  <span>{{ item.name }}</span
                  ><span
                    >{{ tabPosition1 == 1 ? item.money : item.num
                    }}<i v-if="tabPosition1 == 2">家</i></span
                  >
                </div>
              </div>
              <div class="pie-charts">
                <PieChart
                  :chartData="pieChartData"
                  :titleName="cunguan + '%'"
                ></PieChart>
              </div>

              <div class="pie-chart-value">
                <div class="pie-chart-value-top">
                  <div class="pie-chart-value-top1">存管商户</div>
                  <div class="pie-chart-value-top2">
                    {{ tabPosition1 == 1 ? "存管金额(元)" : "存管数量(家)" }}
                  </div>
                  <div class="pie-chart-value-top3">占比</div>
                </div>
                <div
                    class="pie-chart-value-val"
                    v-for="item in pieChartData.seriesData.slice(
                    0,
                    pieChartData.seriesData.length - 1
                  )"
                    :key="item.name"
                >
                  <div class="pie-chart-value-top1">
                    <div class="pie-chart-value-top1-color"></div>
                    {{ item.name }}
                  </div>
                  <div class="pie-chart-value-top2" :title="item.value">
                    {{ item.value }}
                  </div>
                  <div class="pie-chart-value-top3" :title="item.ratio">
                    {{ item.ratio }}
                  </div>
                </div>
              </div>
            </div>

            <div class="clearfix-box-child">
              <div
                  class="txt"
                  v-for="item in statisticsData.slice(1)"
                  :key="item.name"
              >
                <span>{{ item.name }}</span
                ><span
              >{{ tabPosition1 == 1 ? item.money : item.num
                }}<i v-if="tabPosition1 == 2">家</i></span
              >
              </div>
              <div class="pie-charts">
                <PieChart
                    :chartData="pieChartData2"
                    :titleName="baoxian + '%'"
                ></PieChart>
              </div>
              <div class="pie-chart-value">
                <div class="pie-chart-value-top">
                  <div class="pie-chart-value-top1">保险商户</div>
                  <div class="pie-chart-value-top2">
                    {{ tabPosition1 == 1 ? "保险金额(元)" : "保险数量(家)" }}
                  </div>
                  <div class="pie-chart-value-top3">占比</div>
                </div>
                <div
                    class="pie-chart-value-val"
                    v-for="item in pieChartData2.seriesData.slice(
                    0,
                    pieChartData2.seriesData.length - 1
                  )"
                    :key="item.name"
                >
                  <div class="pie-chart-value-top1">
                    <div class="pie-chart-value-top1-color"></div>
                    {{ item.name }}
                  </div>
                  <div class="pie-chart-value-top2" :title="item.value">
                    {{ item.value }}
                  </div>
                  <div class="pie-chart-value-top3" :title="item.ratio">
                    {{ item.ratio }}
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix-box-child">
              <div class="clearfix">
                <div
                    class="txt"
                    v-for="item in statisticsData.slice(0, 1)"
                    :key="item.name"
                >
                  <span> 信托 </span
                  ><span
                >{{ tabPosition1 == 1 ? "0.00" : "0.00"
                  }}<i v-if="tabPosition1 == 2">家</i></span
                >
                </div>
              </div>
              <div class="pie-charts">
                <PieChart
                    :chartData="pieChartData3"
                    :titleName="'0.00' + '%'"
                ></PieChart>
              </div>

              <div class="pie-chart-value">
                <div class="pie-chart-value-top">
                  <div class="pie-chart-value-top1">信托商户</div>
                  <div class="pie-chart-value-top2">
                    {{ tabPosition1 == 1 ? "信托金额(元)" : "信托数量(家)" }}
                  </div>
                  <div class="pie-chart-value-top3">占比</div>
                </div>
                <div
                    class="pie-chart-value-val"
                    v-for="item in pieChartData3.seriesData"
                    :key="item.name"
                >
                  <div class="pie-chart-value-top1">
                    <div class="pie-chart-value-top1-color"></div>
                    {{ item.name }}
                  </div>
                  <div class="pie-chart-value-top2" :title="item.value">
                    {{ item.value }}
                  </div>
                  <div class="pie-chart-value-top3" :title="item.ratio">
                    {{ item.ratio }}
                  </div>
                </div>
              </div>
            </div>

            <div class="clearfix-box-child">
              <div
                  class="txt"
                  v-for="item in statisticsData.slice(1)"
                  :key="item.name"
              >
                <span> 担保 </span
                ><span
              >{{ tabPosition1 == 1 ? "0.00" : "0.00"
                }}<i v-if="tabPosition1 == 2">家</i></span
              >
              </div>
              <div class="pie-charts">
                <PieChart
                    :chartData="pieChartData4"
                    :titleName="'0.00' + '%'"
                ></PieChart>
              </div>
              <div class="pie-chart-value">
                <div class="pie-chart-value-top">
                  <div class="pie-chart-value-top1">担保商户</div>
                  <div class="pie-chart-value-top2">
                    {{ tabPosition1 == 1 ? "担保金额(元)" : "担保数量(家)" }}
                  </div>
                  <div class="pie-chart-value-top3">占比</div>
                </div>
                <div
                    class="pie-chart-value-val"
                    v-for="item in pieChartData4.seriesData"
                    :key="item.name"
                >
                  <div class="pie-chart-value-top1">
                    <div class="pie-chart-value-top1-color"></div>
                    {{ item.name }}
                  </div>
                  <div class="pie-chart-value-top2" :title="item.value">
                    {{ item.value }}
                  </div>
                  <div class="pie-chart-value-top3" :title="item.ratio">
                    {{ item.ratio }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 资金监管过程及变化情况  -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>资金监管过程及变化情况</span>
          </div>
        </div>
        <div class="tab-box">
          <el-radio-group v-model="tabPosition7"  class="tab-btns four">
            <el-radio-button label="1">存管</el-radio-button>
            <el-radio-button label="2">保险</el-radio-button>
            <el-radio-button label="3">信托</el-radio-button>
            <el-radio-button label="4">担保</el-radio-button>
          </el-radio-group>
        </div>
        <div class="element-tab">
          <DateSelect @dateChange="dateChange"></DateSelect>
          <HomeSuperviseItem
              v-loading="itemLoading"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :itemList="itemList"
              :type="tabPosition7"
          ></HomeSuperviseItem>
        </div>
      </div>

      <!-- 监管资金新增变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName2 }}新增变化趋势</span>
          </div>
          <KcSelect
              isBlue
              right="0"
              top="-35"
              @popupVal="popupRadio1"
          ></KcSelect>
        </div>
        <div class="charts">
          <LineChart
              v-loading="barChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="barChartData"
              :legendData="legendData"
              :units="unitData"
              :yAxis="['']"
              :yAxisIndex="['0']"
          ></LineChart>
          <!-- <BarChart :chartData="barChartData" :legendData="['存管', '保险']"></BarChart> -->
        </div>
        <Tab :optionData="optionData" @popupswitch="popupTabPosition2"></Tab>
      </div>
      <!-- 预付码累计变化趋势 折线图图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName3 }}累计变化趋势</span>
          </div>

          <KcSelect
              isBlue
              right="0"
              top="-35"
              @popupVal="popupRadio2"
          ></KcSelect>
        </div>
        <div class="charts">
          <LineChart
              v-loading="lineChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="lineChartData"
              :legendData="legendData2"
              :units="unitData2"
              :yAxis="['']"
              :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <Tab :optionData="optionData3" @popupswitch="popupTabPosition3"></Tab>
      </div>
      <!-- 监管资金按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName4 }}地区分布数量统计</span>
          </div>

          <KcSelect
              isBlue
              keyLabel="haripinType"
              :optionData="options"
              right="0"
              top="-35"
              @popupVal="popupHairpinType1"
          ></KcSelect>
          <Tabhover
              :optionData="optionData"
              @popupswitch="popupTabPosition4"
          ></Tabhover>
          <!-- <KcSelect right="120" top="-35" keyLabel="haripinType" :optionData="options" @popupVal="popupHairpinType1"></KcSelect> -->
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <template v-if="tabPosition4 == 0">
              <el-table-column
                  prop="money"
                  :key="1"
                  :width="0.30 * $bodyWidth"
                  label="监管资金金额"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.money }}</span
                  ><i
                    :class="
                      scope.row.moneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.moneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.moneySign > 0
                        ? "+" + scope.row.moneySign
                        : scope.row.moneySign < 0
                        ? "" + scope.row.moneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                  prop="custodyMoney"
                  label="存管商户发卡金额"
                  :width="0.38 * $bodyWidth"
                  :key="2"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyMoney }}</span
                  ><i
                    :class="
                      scope.row.custodyMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.custodyMoneySign > 0
                        ? "+" + scope.row.custodyMoneySign
                        : scope.row.custodyMoneySign < 0
                        ? "" + scope.row.custodyMoneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  label="保险商户发卡金额"
                  :width="0.38 * $bodyWidth"
                  :key="3"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceMoney }}</span
                  ><i
                    :class="
                      scope.row.insuranceMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.insuranceMoneySign > 0
                        ? "+" + scope.row.insuranceMoneySign
                        : scope.row.insuranceMoneySign < 0
                        ? "" + scope.row.insuranceMoneySign
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition4 == 1">
              <el-table-column prop="num" :width="0.25 * $bodyWidth" label="总商户数" :key="1" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.num }}</span
                  ><i
                    :class="
                      scope.row.numSign > 0
                        ? 'num-badge-blue'
                        : scope.row.numSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.numSign > 0
                        ? "+" + scope.row.numSign
                        : scope.row.numSign < 0
                        ? "" + scope.row.numSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="custodyNum"
                  label="存管商户数"
                  :width="0.25 * $bodyWidth"
                  :key="2"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyNum }}</span
                  ><i
                    :class="
                      scope.row.custodyNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.custodyNumSign > 0
                        ? "+" + scope.row.custodyNumSign
                        : scope.row.custodyNumSign < 0
                        ? "" + scope.row.custodyNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceNum"
                  label="保险商户数"
                  :width="0.25 * $bodyWidth"
                  :key="3"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceNum }}</span
                  ><i
                    :class="
                      scope.row.insuranceNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.insuranceNumSign > 0
                        ? "+" + scope.row.insuranceNumSign
                        : scope.row.insuranceNumSign < 0
                        ? "" + scope.row.insuranceNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>
            <el-table-column
                prop="insuranceNum"
                :key="4"
                :width="0.38 * $bodyWidth"
                label="信托商户发卡金额"
                sortable
            >
              <template slot-scope="scope">
                <span class="num-box">{{ 0 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
                  >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
                >
              </template>
            </el-table-column>
            <el-table-column
                prop="insuranceNum"
                :key="5"
                :width="0.38 * $bodyWidth"
                label="担保商户发卡金额"
                sortable
            >
              <template slot-scope="scope">
                  <span class="num-box">{{ 0 }}</span
                  ><i
                  :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
              >{{
                  0 > 0
                      ? "+" + 0
                      : 0 < 0
                      ? "" + 0
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
            >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
      <!-- 监管资金按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>监管{{ typeName5 }}领域/行业分布数量统计</span>
          </div>
          <!--          <el-radio-group-->
          <!--            v-model="tabPosition5"-->
          <!--            class="tab-select-btn right-size"-->
          <!--          >-->
          <!--            <el-radio-button label="0">资金</el-radio-button>-->
          <!--            <el-radio-button label="1">商户</el-radio-button>-->
          <!--          </el-radio-group>-->
          <!--          <div class="h-selct">-->
          <!--            <el-select v-model="hairpinType2" placeholder="请选择" size="mini">-->
          <!--              <el-option label="全部" value=""></el-option>-->
          <!--              <el-option-->
          <!--                v-for="item in options"-->
          <!--                :key="item.haripinType"-->
          <!--                :label="item.name"-->
          <!--                :value="item.haripinType"-->
          <!--              ></el-option>-->
          <!--            </el-select>-->
          <!--          </div>-->
          <KcSelect
              isBlue
              keyLabel="haripinType"
              :optionData="options"
              right="0"
              top="-35"
              @popupVal="popupHairpinType2"
          ></KcSelect>
          <Tabhover
              :optionData="optionData"
              @popupswitch="popupTabPosition5"
          ></Tabhover>
          <!-- <KcSelect  right="120" top="-35" keyLabel="haripinType" :optionData="options" @popupVal="popupHairpinType2"></KcSelect> -->
        </div>
        <div class="element-tab">
          <el-table
              :data="tableDataAll2"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              row-key="industryId"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort2"
              :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
                prop="industryName"
                :key="0"
                label="领域/行业"
                :width="0.32 * $bodyWidth"
                fixed
                sortable
            >
            </el-table-column>
            <template v-if="tabPosition5 == 0">
              <el-table-column
                  prop="money"
                  :key="1"
                  label="监管资金金额"
                  :width="0.30 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.money }}</span
                  ><i
                    :class="
                      scope.row.moneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.moneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.moneySign > 0
                        ? "+" + scope.row.moneySign
                        : scope.row.moneySign < 0
                        ? "" + scope.row.moneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="custodyMoney"
                  :key="2"
                  label="存管商户发卡金额"
                  :width="0.38 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyMoney }}</span
                  ><i
                    :class="
                      scope.row.custodyMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.custodyMoneySign > 0
                        ? "+" + scope.row.custodyMoneySign
                        : scope.row.custodyMoneySign < 0
                        ? "" + scope.row.custodyMoneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceMoney"
                  :key="3"
                  label="保险商户发卡金额"
                  :width="0.38 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceMoney }}</span
                  ><i
                    :class="
                      scope.row.insuranceMoneySign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceMoneySign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.insuranceMoneySign > 0
                        ? "+" + scope.row.insuranceMoneySign
                        : scope.row.insuranceMoneySign < 0
                        ? "" + scope.row.insuranceMoneySign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition5 == 1">
              <el-table-column prop="num" :key="1" :width="0.25 * $bodyWidth" label="总商户数" sortable>
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.num }}</span
                  ><i
                    :class="
                      scope.row.numSign > 0
                        ? 'num-badge-blue'
                        : scope.row.numSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.numSign > 0
                        ? "+" + scope.row.numSign
                        : scope.row.numSign < 0
                        ? "" + scope.row.numSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="custodyNum"
                  :key="2"
                  label="存管商户数"
                  :width="0.25 * $bodyWidth"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.custodyNum }}</span
                  ><i
                    :class="
                      scope.row.custodyNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.custodyNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.custodyNumSign > 0
                        ? "+" + scope.row.custodyNumSign
                        : scope.row.custodyNumSign < 0
                        ? "" + scope.row.custodyNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
              <el-table-column
                  prop="insuranceNum"
                  :key="3"
                  :width="0.25 * $bodyWidth"
                  label="保险商户数"
                  sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.insuranceNum }}</span
                  ><i
                    :class="
                      scope.row.insuranceNumSign > 0
                        ? 'num-badge-blue'
                        : scope.row.insuranceNumSign < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                >{{
                    scope.row.insuranceNumSign > 0
                        ? "+" + scope.row.insuranceNumSign
                        : scope.row.insuranceNumSign < 0
                        ? "" + scope.row.insuranceNumSign
                        : ""
                  }}</i
                >
                </template>
              </el-table-column>
            </template>
            <el-table-column
                prop="insuranceMoney"
                label="信托商户发卡金额"
                :width="0.38 * $bodyWidth"
                sortable
            >
              <template slot-scope="scope">
                  <span class="num-box">{{ 0 }}</span
                  ><i
                  :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
              >{{
                  0 > 0
                      ? "+" + 0
                      : 0 < 0
                      ? "" + 0
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
            <el-table-column
                prop="insuranceMoney"
                label="担保商户发卡金额"
                :width="0.38 * $bodyWidth"
                sortable
            >
              <template slot-scope="scope">
                  <span class="num-box">{{ 0 }}</span
                  ><i
                  :class="
                      0 > 0
                        ? 'num-badge-blue'
                        : 0 < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
              >{{
                  0 > 0
                      ? "+" + 0
                      : 0 < 0
                      ? "" + 0
                      : ""
                }}</i
              >
              </template>
            </el-table-column>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
            >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
      <!-- 金融机构业绩排行 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>金融机构业绩排行</span>
          </div>
          <!--          <el-radio-group v-model="tabPosition6" class="tab-select-btn">-->
          <!--            <el-radio-button label="0">银行</el-radio-button>-->
          <!--            <el-radio-button label="1">保险</el-radio-button>-->
          <!--          </el-radio-group>-->
          <KcSelect
              isBlue
              keyLabel="haripinType"
              :optionData="optionData2"
              right="0"
              top="-35"
              @popupVal="popupTabPosition6"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
              :data="tableData3"
              style="width: 100%"
              row-key="id"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="date" :label="tabPosition6 == 2 ? '信托公司' : tabPosition6 == 3 ? '担保公司' : '银行'" sortable fixed  :width="0.25 * $bodyWidth">
            </el-table-column>
            <el-table-column prop="name" label="开户业绩" sortable :width="0.25 * $bodyWidth">
            </el-table-column>
            <el-table-column prop="address" :label="tabPosition6 == 2 ? '托管业绩' : tabPosition6 == 3 ? '担保业绩' : '存管业绩'" sortable :width="0.25 * $bodyWidth">
            </el-table-column>

            <el-table-column prop="address" label="普惠金融" sortable :width="0.25 * $bodyWidth">
            </el-table-column>
            <el-table-column prop="address" label="服务评价" sortable :width="0.25 * $bodyWidth">
            </el-table-column>
          </el-table>
          <div class="page-btn">
            <el-button type="text"
            >收起全部<i
                class="el-icon-d-arrow-left el-icon--right rotate-top"
            ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PieSupervise"; //饼图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import BarChart from "@/components/charts/bar";
import Tab from "@/components/tab.vue";
import Tabhover from "@/components/tabhover.vue";
import DateSelect from "@/components/dateSelect/dateSelect";
import HomeSuperviseItem from "@/components/homeSupervise-item/homeSupervise-item";
import {
  keepStatistics,
  moneyStatistics,
  busStatistics,
  fundSupervisionAdd,
  changingTrendOfBus,
  fundSupervisionCityStatistics,
  fundIndustryStatistics,
  bankStatistics,
  insuranceBankStatistics,
  processAndChanging,
} from "@/api/homesupervise";

import { cardType } from "@/api/common";

export default {
  name: "HomeSupervise",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      barChart1: false,
      lineChart2: false,
      itemLoading: false,
      optionData: [
        { name: "资金", haripinType: 0 },
        { name: "商户", haripinType: 1 },
      ],
      optionData2: [
        { name: "银行", haripinType: 0 },
        { name: "保险", haripinType: 1 },
        { name: "信托", haripinType: 3 },
        { name: "担保", haripinType: 4 },
      ],
      optionData3: [
        { name: "存管", haripinType: 1 },
        { name: "保险", haripinType: 2 },
        { name: "信托", haripinType: 3 },
        { name: "担保", haripinType: 4 },
      ],

      outing: [],
      options: [],
      forCodeData: [
         {
                name1: "存管商户交易总额",
                val1: "0.00",
                name2: "存管金额",
                val2: "0.00",
                name3: "实际存管率",
                val3:  "0.00" + "%",
                name4: "提现资金",
                val4: "0.00"
              },
              {
                name1: "投保商户交易总额 ",
                val1: "0.00",
                name2: "保险金额",
                val2: "0.00",
                name3: "承保率",
                val3: "0.00" + "%",
                name4: "预付交易额",
                val4: "0.00",
              },
              {
                name1: "信托商户交易总额 ",
                val1: "0.00",
                name2: "托管金额",
                val2: "0.00",
                name3: "实际托管率",
                val3: "0.00" + "%",
                name4: "提现资金",
                val4: "0.00",
              },
              {
                name1: "担保商户交易总额  ",
                val1: "0.00",
                name2: "担保金额",
                val2: "0.00",
                name3: "担保率",
                val3: "0.00" + "%",
                name4: "预付交易额",
                val4: "0.00",
              },
      ],
      typeName: "资金",
      typeName2: "资金",
      typeName3: "存管",
      typeName4: "资金",
      typeName5: "资金",
      tabPosition1: 1,
      tabPosition2: 0,
      tabPosition3: 1,
      tabPosition4: 0,
      tabPosition5: 0,
      tabPosition6: 0,
      tabPosition7: 1,
      isTowPie: false,
      statisticsData: [],
      cunguan: "",
      baoxian: "",
      pieChartData: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [],
      },
      pieChartData3: {
        seriesData: [],
      },
      pieChartData4: {
        seriesData: [],
      },
      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            value: [],
          },
          {
            name: "保险商户发卡金额",
            value: [],
          },
          {
            name: "信托商户发卡金额",
            value: [],
          },
          {
            name: "担保商户发卡金额",
            value: [],
          },
        ],
      },
      legendData: [
        "存管商户发卡金额",
        "保险商户发卡金额",
        "信托商户发卡金额",
        "担保商户发卡金额",
      ],
      unitData: ["元", "元", "元", "元"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            data: [],
          },
          {
            name: "保险商户发卡金额",
            data: [],
          },
        ],
      },
      legendData2: ["存管金额", "提现金额", "实际存管率"],
      unitData2: ["元", "元","%"],
      hairpinType1: "",
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      hairpinType2: "",
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      showAll3: false,
      tableDataAll3: [],
      tableData3: [],
      pieSmallChartData: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      pieSmallChartData2: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      itemList: [
        {
          name: "T+0",
          tradeMoney: "0",
          depositScale: "0",
          cashScale: "0",
        },
        {
          name: "T+1",
          shouldDepositMoney: "0",
          shouldDepositScale: "0",
          realityDepositMoney: "0",
          realityDepositScale: "0",
          cashScale: "0",
        },
        {
          name: "TN-1",
          shouldDepositMoney: "0",
          shouldDepositScale: "0",
          realityDepositMoney: "0",
          realityDepositScale: "0",
          cashScale: "0",
        },
      ],
      date: this.dataAdd0(new Date().getTime()),
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    tabPosition1: function (val) {
      //console.log(val)
      //this.isTowPie = false
      this.getBankStatistics();
      this.getInsuranceBankStatistics();
      if (val == 1) {
        this.moneyStatistics();
      } else {
        this.busStatistics();
      }
      this.typeName = val == 1 ? "资金" : "商户";
    },
    radio1: function (val) {
      this.fundSupervisionAdd();
    },
    tabPosition2: function (val) {
      this.fundSupervisionAdd();
      this.legendData =
          val == 0
              ? [
                "存管商户发卡金额",
                "保险商户发卡金额",
                "信托商户发卡金额",
                "担保商户发卡金额",
              ]
              : ["存管商户数量", "保险商户数量", "信托商户数量", "担保商户数量"];
      this.unitData =
          val == 0 ? ["元", "元", "元", "元"] : ["家", "家", "家", "家"];
      this.typeName2 = val == 0 ? "资金" : "商户";
    },
    radio2: function (val) {
      this.changingTrendOfBus();
    },
    tabPosition3: function (val) {
      this.changingTrendOfBus();
      // this.legendData2 =
      //   val == 0
      //     ? ["存管商户发卡金额", "保险商户发卡金额"]
      //     : ["存管商户数量", "保险商户数量"];
      // this.unitData2 = val == 0 ? ["元", "元"] : ["家", "家"];
      // this.typeName3 = val == 0 ? "资金" : "商户";
      this.legendData2 =
          val == 1
              ? ["存管金额", "提现金额", "实际存管率"]
              : val == 2
              ? ["投保商户交易总额", "保险金额", "承保率"]
              : val == 3
                  ? ["托管金额", "提现金额", "实际存管率"]
                  : ["担保商户交易总额", "担保金额", "担保率"];
      this.unitData2 =
          val == 1
              ? ["元", "元","%"]
              : val == 2
              ? ["元", "元","%"]
              : val == 3
                  ? ["元", "元","%"]
                  : ["元", "元","%"];
      this.typeName3 = val == 1 ? "存管" :val == 2 ?"投保":val == 3 ?"托管": "担保";
    },
    tabPosition4: function (val) {
      console.log('val',val)
      // this.fundSupervisionCityStatistics();
      this.typeName4 = val.haripinType == 0 ? "资金" : "商户";
    },
    hairpinType1: function (val) {
      this.fundSupervisionCityStatistics();
    },
    tabPosition5: function (val) {
      console.log(val);
      // this.fundIndustryStatistics();
      this.typeName5 = val.haripinType == 0 ? "资金" : "商户";
    },
    tabPosition7: function (val) {
      this.processAndChanging();
    },
    hairpinType2: function (val) {
      this.fundIndustryStatistics();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    //日期补0，传时间对象或时间戳
    dataAdd0(date) {
      var year = new Date(date).getFullYear() + "-";
      var month = (new Date(date).getMonth() + 1 + "").padStart(2, "0") + "-";
      var day = (new Date(date).getDate() + "").padStart(2, "0");
      return year + month + day;
    },
    intData() {
      this.cardType();
      this.keepStatistics();
      this.moneyStatistics();
      this.fundSupervisionAdd();
      this.fundSupervisionCityStatistics();
      this.fundIndustryStatistics();
      this.getBankStatistics();
      this.getInsuranceBankStatistics();
      this.changingTrendOfBus();
      this.processAndChanging();
    },
    popupRadio1(val) {
      this.radio1 = val;
    },
    popupTabPosition2(val) {
      this.tabPosition2 = val.haripinType;
    },
    popupRadio2(val) {
      this.radio2 = val;
    },
    popupTabPosition3(val) {
      this.tabPosition3 = val.haripinType;
    },
    popupTabPosition4(val) {
      this.tabPosition4 = val.haripinType;
    },
    popupTabPosition5(val) {
      this.tabPosition5 = val.haripinType;
    },
    popupHairpinType1(val) {
      console.log(val)
      this.hairpinType1 = val;
      // this.typeName4 = val.name;
    },
    popupHairpinType2(val) {
      this.hairpinType2 = val;
      // this.typeName5 = val.name;
      console.log(val, "----");
    },
    popupTabPosition6(val) {
      this.tabPosition6 = val;
    },
    cardType() {
      cardType().then((res) => {
        res.data.unshift({ name: "全部", haripinType: "" });
        this.options = res.data;
      });
    },
    dateChange(val) {
      console.log("日期变化", val);
      this.date = val;
      this.processAndChanging();
    },
    processAndChanging() {
      this.itemLoading = true;
      processAndChanging({
        date: this.date,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        reqType: this.tabPosition7,
      }).then((res) => {
        this.itemLoading = false;
        if (this.tabPosition7 == 1) {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: res.data.amountOfTrade,
              depositScale: res.data.rateOfDeposit,
              cashScale: res.data.rateOfCash0,
            },
            {
              name: "T+1",
              shouldDepositMoney: res.data.yamountOfDeposit,
              shouldDepositScale: res.data.yrateOfDeposit,
              realityDepositMoney: res.data.samountOfDeposit,
              realityDepositScale: res.data.srateOfDeposit,
              cashScale: res.data.rateOfCash1,
            },
            {
              name: "TN-1",
              shouldDepositMoney: res.data.namountOfDeposit,
              shouldDepositScale: res.data.yrateOfDeposit,
              realityDepositMoney: res.data.nsamountOfDeposit,
              realityDepositScale: res.data.nsrateOfDeposit,
              cashScale: res.data.rateOfCashN,
            },
          ];
        } else if (this.tabPosition7 == 3) {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: 0,
              depositScale: 0,
              cashScale: 0,
            },
            {
              name: "T+1",
              shouldDepositMoney: 0,
              shouldDepositScale: 0,
              realityDepositMoney: 0,
              realityDepositScale: 0,
              cashScale: 0,
            },
            {
              name: "TN-1",
              shouldDepositMoney: 0,
              shouldDepositScale: 0,
              realityDepositMoney: 0,
              realityDepositScale: 0,
              cashScale: 0,
            },
          ];
        } else if (this.tabPosition7 == 4) {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: 0,
              rateOfUnderwrite0: 0,
              cashScale: 0,
            },
            {
              name: "T+1",
              amountOfInsured1: 0,
              rateOfUnderwrite1: 0,
              cashScale: 0,
            },
            {
              name: "TN-1",
              amountOfInsured1: 0,
              rateOfUnderwrite1: 0,
              cashScale: 0,
            },
          ];
        } else {
          this.itemList = [
            {
              name: "T+0",
              tradeMoney: res.data.amountOfTrade,
              rateOfUnderwrite0: res.data.rateOfUnderwrite0,
              cashScale: res.data.rateOfCash0,
            },
            {
              name: "T+1",
              amountOfInsured1: res.data.amountOfInsured1,
              rateOfUnderwrite1: res.data.rateOfUnderwrite1,
              cashScale: res.data.rateOfCash1,
            },
            {
              name: "TN-1",
              amountOfInsured1: res.data.amountOfInsured1,
              rateOfUnderwrite1: res.data.rateOfUnderwrite1,
              cashScale: res.data.rateOfCash1,
            },
          ];
        }
      });
    },
    keepStatistics() {
      keepStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
          .then((res) => {
            // console.log(res.data);
            // this.forCodeData = res.data;
            this.forCodeData = [
              {
                name1: "存管商户交易总额",
                val1: res.data[0].totalMoney,
                name2: "存管金额",
                val2: res.data[0].keepMoney,
                name3: "实际存管率",
                val3: res.data[0].ratio + "%",
                name4: "提现资金",
                val4: res.data[0].cashMoney,
              },
              {
                name1: "投保商户交易总额 ",
                val1: res.data[1].totalMoney,
                name2: "保险金额",
                val2: res.data[1].totalMoney,
                name3: "承保率",
                val3: res.data[1].ratio + "%",
                name4: "预付交易额",
                val4: res.data[1].totalMoney,
              },
              {
                name1: "信托商户交易总额 ",
                val1: "0.00",
                name2: "托管金额",
                val2: "0.00",
                name3: "实际托管率",
                val3: "0.00" + "%",
                name4: "提现资金",
                val4: "0.00",
              },
              {
                name1: "担保商户交易总额  ",
                val1: "0.00",
                name2: "担保金额",
                val2: "0.00",
                name3: "担保率",
                val3: "0.00" + "%",
                name4: "预付交易额",
                val4: '0.00',
              },
            ];
          })
          .catch((err) => {
            console.log(err);
          });
    },
    changingTrendOfBus() {
      this.lineChart2 = true;
      changingTrendOfBus({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
        reqType: this.tabPosition3,
      }).then((res) => {
        this.lineChart2 = false;
        //console.log('监管资金累计变化趋势',res)
        if (res.data != null) {
          let amountOfDeposit = [],
              amountOfTrade = [],
              amountOfWithdraw = [],
              rateOfDeposit = [],
              amountOfInsured = [],
              amountOfTradeb = [],
              rateOfUnderwrite = [];
          res.data.dataList.map((v, i) => {
            amountOfDeposit.push(v.amountOfDeposit);
            amountOfTrade.push(v.amountOfTrade);
            amountOfWithdraw.push(v.amountOfWithdraw);
            rateOfDeposit.push(v.rateOfDeposit);
            amountOfInsured.push(v.amountOfInsured);
            amountOfTradeb.push(v.amountOfTradeb);
            rateOfUnderwrite.push(v.rateOfUnderwrite);
          });

          let newdata = [];
          res.data.xlist.map((v, i) => {
            newdata.push("0.00");
          });
          this.lineChartData = {
            xAxisData: res.data.xlist,
            seriesData: [
              {
                name:
                    this.tabPosition3 == 1
                        ? "存管金额"
                        : this.tabPosition3 == 2
                        ? "投保商户交易总额"
                        : this.tabPosition3 == 3
                            ? "托管金额"
                            : this.tabPosition3 == 4
                                ? "担保商户交易总额"
                                : "",
                type: "bar",
                data:
                    this.tabPosition3 == 1
                        ? amountOfDeposit
                        : this.tabPosition3 == 2
                        ? amountOfTradeb
                        : this.tabPosition3 == 3
                            ? newdata
                            : this.tabPosition3 == 4
                                ? newdata
                                : [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "提现金额"
                        : this.tabPosition3 == 2
                        ? "保险金额"
                        : this.tabPosition3 == 3
                            ? "提现金额"
                            : this.tabPosition3 == 4
                                ? "担保金额"
                                : "",
                type: "bar",
                data:
                    this.tabPosition3 == 1
                        ? amountOfWithdraw
                        : this.tabPosition3 == 2
                        ? amountOfInsured
                        : this.tabPosition3 == 3
                            ? newdata
                            : this.tabPosition3 == 4
                                ? newdata
                                : [],
              },
              {
                name:
                    this.tabPosition3 == 1
                        ? "实际存管率"
                        : this.tabPosition3 == 2
                        ? "承保率"
                        : this.tabPosition3 == 3
                            ? "实际存管率"
                            : this.tabPosition3 == 4
                                ? "担保率"
                                : "",
                type: "line",
                data:
                    this.tabPosition3 == 1
                        ? rateOfDeposit
                        : this.tabPosition3 == 2
                        ? rateOfUnderwrite
                        : this.tabPosition3 == 3
                            ? newdata
                            : this.tabPosition3 == 4
                                ? newdata
                                : [],
              },
            ],
          };
        } else {
          this.lineChartData = {
            xAxisData: [],
            seriesData: [
              {
                name:
                    this.tabPosition3 == 0
                        ? "存管商户发卡金额"
                        : this.tabPosition3 == 1
                        ? "存管商户数量"
                        : "",
                data: [],
              },
              {
                name:
                    this.tabPosition3 == 0
                        ? "保险商户发卡金额"
                        : this.tabPosition3 == 1
                        ? "保险商户数量"
                        : "",
                data: [],
              },
            ],
          };
        }
      });
    },
    getBankStatistics() {
      let _this = this;
      bankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        // console.log("应存管发卡银行/商户占比", res);
        let arr = [];
        _this.pieChartData.seriesData = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            arr.push({
              name: item.name,
              value: item.money,
              ratio: item.cratio,
              unit: "元",
            });
          } else if (_this.tabPosition1 == 2) {
            arr.push({
              name: item.name,
              value: item.num,
              ratio: item.nratio,
              unit: "家",
            });
          }
          console.log(arr, 11111111);
          _this.pieChartData.seriesData = arr;
        });
      });
    },
    getInsuranceBankStatistics() {
      let _this = this;
      insuranceBankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        // console.log("保险资金各银行/商户占比", res);
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            arr.push({
              name: item.name,
              value: item.money,
              ratio: item.cratio,
              unit: "元",
            });
            // if (item.name == "其他") {
            //   arr.push({
            //     name: item.name,
            //     value: item.money,
            //     ratio: item.cratio,
            //     unit: "元",
            //     selected: false,
            //     itemStyle: {
            //       color: "#0000",
            //       borderWidth: 0,
            //     },
            //     label: { show: false },
            //     hoverAnimation: false,
            //   });
            // } else {
            //   arr.push({
            //     name: item.name,
            //     value: item.money,
            //     ratio: item.cratio,
            //     unit: "元",
            //   });
            // }
          } else if (_this.tabPosition1 == 2) {
            arr.push({
              name: item.name,
              value: item.num,
              ratio: item.nratio,
              unit: "家",
            });
          }
          _this.pieChartData2.seriesData = arr;
        });
      });
    },
    toOnePie() {
      if (this.tabPosition1 == 1) {
        this.moneyStatistics();
      } else if (this.tabPosition1 == 2) {
        this.busStatistics();
      }
      this.isTowPie = false;
    },
    moneyStatistics() {
      let _this = this;
      moneyStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log("资金占比", res);
        _this.cunguan = 0;
        _this.baoxian = 0;
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    busStatistics() {
      let _this = this;
      busStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('商户占比',res)
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    fundSupervisionAdd() {
      this.barChart1 = true;
      fundSupervisionAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      }).then((res) => {
        this.barChart1 = false;
        let newdata = [];
        let newdata2 = [];
        res.data.x_axis.map((v, i) => {
          newdata.push("0.00");
          newdata2.push(0);
        });
        // console.log("监管资金新增变化趋势", res);
        if (res.data != null) {
          this.barChartData = {
            xAxisData: res.data.x_axis,
            seriesData: [
              {
                name:
                    this.tabPosition2 == 0
                        ? "存管商户发卡金额"
                        : this.tabPosition2 == 1
                        ? "存管商户数量"
                        : "",
                data:
                    this.tabPosition2 == 0
                        ? res.data.custodyMoney
                        : this.tabPosition2 == 1
                        ? res.data.custodyNum
                        : [],
              },
              {
                name:
                    this.tabPosition2 == 0
                        ? "保险商户发卡金额"
                        : this.tabPosition2 == 1
                        ? "保险商户数量"
                        : "",
                data:
                    this.tabPosition2 == 0
                        ? res.data.insurancesMoney
                        : this.tabPosition2 == 1
                        ? res.data.insurancesNum
                        : [],
              },
              {
                name:
                    this.tabPosition2 == 0
                        ? "信托商户发卡金额"
                        : this.tabPosition2 == 1
                        ? "信托商户数量"
                        : "",
                data:
                    this.tabPosition2 == 0
                        ? newdata
                        : this.tabPosition2 == 1
                        ? newdata2
                        : [],
              },
              {
                name:
                    this.tabPosition2 == 0
                        ? "担保商户发卡金额"
                        : this.tabPosition2 == 1
                        ? "担保商户数量"
                        : "",
                data:
                    this.tabPosition2 == 0
                        ? newdata
                        : this.tabPosition2 == 1
                        ? newdata2
                        : [],
              },
            ],
          };
        } else {
          this.barChartData = {
            xAxisData: [],
            seriesData: [
              {
                name:
                    this.tabPosition2 == 0
                        ? "存管商户发卡金额"
                        : this.tabPosition2 == 1
                        ? "存管商户数量"
                        : "",
                data: [],
              },
              {
                name:
                    this.tabPosition2 == 0
                        ? "保险商户发卡金额"
                        : this.tabPosition2 == 1
                        ? "保险商户数量"
                        : "",
                data: [],
              },
            ],
          };
        }
      });
    },
    fundSupervisionCityStatistics() {
      let _this = this;
      this.loading1 = true;
      fundSupervisionCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data;
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      fundSupervisionCityStatistics({
        hairpinType: this.hairpinType1,
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        //console.log('预付码按城市/地区统计-二级',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data);
      });
    },
    fundIndustryStatistics() {
      this.loading2 = true;
      fundIndustryStatistics({
        hairpinType: this.hairpinType2,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data;
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      fundIndustryStatistics({
        hairpinType: this.hairpinType2,
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    PieChart,
    BarChart,
    PieSmallChart,
    Tab,
    Tabhover,
    DateSelect,
    HomeSuperviseItem,
  },
};
</script>

<style lang="scss" scoped >
.HomeSupervise {
  // 四色码
  .idiot-one {
    .box {
      width: 100%;
      height: 172px;
      padding: 0 30px;
      margin-bottom: 30px;
      text-align: center;
      background: #fff;
      color: #232a24;
      // display: flex;
      .box-left {
        width: 170px;
      }
      .box-right {
        width: calc(100% - 170px);
        .box-header {
          text-align: left;
          margin: 40px 0 10px;
          font-size: 24px;
          span {
            font-size: 30px;
            color: #f9a100;
            margin-left: 10px;
          }
        }
        .box-boxs {
          display: flex;
          flex-wrap: wrap;
          line-height: 1.2;
          // justify-content: space-around;
          .box-one {
            display: inline-block;
            //justify-content: center;
            //flex-wrap: wrap;
            margin-top: 10px;
            margin-right: 20px;
            font-size: 20px;
            span {
              color: #626262;
              // min-width: 20px;
              text-align: left;
            }
          }
        }
      }
      &:nth-child(2) {
        .box-header span {
          color: #18bbff;
        }
      }
      .box-top {
        font-size: 24px;
        color: #232a24;
        display: flex;
        align-items: center;
        height: 80px;
      }
      .box-topnum {
        font-size: 30px;
        margin-left: 5px;
        display: flex;
      }
      .box-topcom {
        font-size: 18px;
        margin-left: 5px;
        line-height: 50px;
      }
      .color1 {
        color: #f9a100;
      }
      .box-abso {
        width: 686px;
        height: 16px;
        border-radius: 8px;
        background: #f1f1f1;
        position: relative;
        .box-abso-child {
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          border-radius: 8px;
          width: 0;
          background-image: linear-gradient(to right, #ffb224, #ff7d3d);
        }
      }
      .box-bottom {
        width: 686px;
        height: 70px;
        font-size: 20px;
        color: #232a24;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &:nth-child(2) {
        .color1 {
          color: #1d67ff;
        }
        .box-abso {
          .box-abso-child {
            background-image: linear-gradient(to right, #0dccff, #4760ff);
          }
        }
      }
      &:nth-child(3) {
        .color1 {
          color: #00c87f;
        }
        .box-abso {
          .box-abso-child {
            background-image: linear-gradient(to right, #00f5a7, #00c87f);
          }
        }
      }
      &:nth-child(4) {
        .color1 {
          color: #00e2da;
        }
        .box-abso {
          .box-abso-child {
            background-image: linear-gradient(to right, #0dfffc, #00e2da);
          }
        }
      }
    }
  }
  .pie-box {
    //background: url("../assets/imgs/pie-bg3.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30px;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-subTitle {
      text-align: right;
      font-size: 24px;
      color: #232a24;
      line-height: 1;
      font-weight: 200;
      padding: 20px 30px 20px 0;
    }

    .clearfix-box {
      width: 750px;
      min-height: 1325px;
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 30px;
      .clearfix-box-child {
        width: 50%;
        min-height: 50%;
      }
    }

    .pie-chart-box {
      .txt {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 24px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 35px;
          color: #1d67ff;
          i {
            font-size: 24px;
            margin-left: 10px;
          }
        }
      }
      .pie-charts {
        padding: 0 30px;
        height: 200px;
      }
    }
    .pie-chart-value {
      width: 100%;
      color: #232a24;
      padding-left: 20px;
      .pie-chart-value-top {
        width: 100%;
        height: 50px;
        font-size: 20px;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        .pie-chart-value-top1 {
          width: 115px;
          height: 100%;
          line-height: 50px;
        }
        .pie-chart-value-top2 {
          width: 140px;
          height: 100%;
          line-height: 50px;
        }
        .pie-chart-value-top3 {
          width: 80px;
          height: 100%;
          line-height: 50px;
        }
      }
      .pie-chart-value-val {
        width: 100%;
        height: 70px;
        font-size: 14px;
        border-bottom: 1px dashed #e1e1e1;
        display: flex;
        .pie-chart-value-top1 {
          width: 115px;
          height: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          .pie-chart-value-top1-color {
            width: 8px;
            height: 8px;
            background: #76fdfc;
            margin-right: 8px;
          }
        }
        .pie-chart-value-top2 {
          width: 140px;
          height: 100%;
          line-height: 70px;
          overflow: hidden;
        }
        .pie-chart-value-top3 {
          width: 80px;
          height: 100%;
          line-height: 70px;
          overflow: hidden;
        }
        &:nth-child(2) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #51f1fe;
            }
          }
        }
        &:nth-child(3) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #4effe8;
            }
          }
        }
        &:nth-child(4) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #01ffb3;
            }
          }
        }
        &:nth-child(5) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #00d489;
            }
          }
        }
        &:nth-child(6) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #d8ff85;
            }
          }
        }
        &:nth-child(7) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #eeeb47;
            }
          }
        }
        &:nth-child(8) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #ffd645;
            }
          }
        }
        &:nth-child(9) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #ff3567;
            }
          }
        }
        &:nth-child(10) {
          .pie-chart-value-top1 {
            .pie-chart-value-top1-color {
              background: #e759b1;
            }
          }
        }
        // '#76fdfc', '#51f1fe', '#4effe8', '#01ffb3', '#00d489', '#d8ff85', '#eeeb47', '#ffd645', '#ff3567', '#e759b1'
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }

  .tab-box {
    display: flex;
    justify-content: center;
    .tab-btns {
      &::v-deep .el-radio-button {
        .el-radio-button__inner {
          width: 214px;
          height: 56px;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &:first-child .el-radio-button__inner {
          border-radius: 10px 0 0 10px;
        }
        &:last-child .el-radio-button__inner {
          border-radius: 0 10px 10px 0;
        }
      }
      &.four{
        margin-top: 30px;
        &::v-deep .el-radio-button{
          .el-radio-button__inner{
            width: 176px;
          }
        }
      }

    }
  }
}
</style>
