<template>
  <div class="tabswitch">
    <div
      class="tabswitchchild"
      :class="index == ind ? 'active' : ''"
      v-for="(val, ind) in optionData"
      :key="ind"
      @click="tabswitchdata(val, ind)"
    >
    <span :style="{color:index == ind?'white':'#232a24'}">{{ val.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    optionData: {
      type: Array,
      default: [],
    },
    istrue: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  components: {},
  methods: {
    tabswitchdata(val, ind) {
      this.index = ind;
      this.$emit("popupswitch", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabswitch {
  width: 100%;
  height: 80px;
  display: flex;
  margin: 0 0 30px 0;
  border-radius: 10px;
  overflow: hidden;
  background: #ececec;
  position: relative;
  padding-right: 2px;
  .tabswitchchild {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    text-align: center;
    font-size: 24px;
    position: relative;
    margin: 2px;
    color: #232a24;
    & > span{
        z-index: 1;
    }
  }
  .tabswitchchild::before {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 0%;
      height: 76px;
      background: #4f77e1;
      border-radius: 15px;
      z-index: 0;
      transition: 0.3s all linear;
       color: white;
  }

   .active~.tabswitchchild::before {
      left: 0;
    }

    .active::before {
      width: 100%;
      left: 0;
      top: 0;
    }
 
  .tabswitchchild:nth-last-child(1) {
    margin-right: 0px;
  }
}
</style>
