<template>
  <div class="homeSupervise-item-wrap">
    <ul>
      <li v-for="(item,ind) in itemList" :key="ind">
        <div class="item-title"></div>
        <div class="item-line" v-if="item.hasOwnProperty('tradeMoney')">
          <span>交易资金：</span>
          <span>{{item.tradeMoney}}<span>元</span></span>
        </div>
        <div class="item-line center" v-if="item.hasOwnProperty('depositScale')">
          <span>{{type == 3 ? '托管率：' : '存管率：'}}</span>
          <div class="item-line-right">
<!--            <div class="item-progress">-->
<!--              <span :style="{width: item.depositScale + '%'}"></span>-->
<!--            </div>-->
            <span class="item-progress-title">{{item.depositScale}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line center" v-if="item.hasOwnProperty('rateOfUnderwrite0')">
          <span>{{type == 4 ? '担保率：' : '承保率：'}}</span>
          <div class="item-line-right">
<!--            <div class="item-progress">-->
<!--              <span :style="{width: item.rateOfUnderwrite0 + '%'}"></span>-->
<!--            </div>-->
            <span class="item-progress-title">{{item.rateOfUnderwrite0}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('shouldDepositMoney')">
          <span>{{type == 3 ? '最低托管资金：' : '最低存管资金：'}}</span>
          <span>{{item.shouldDepositMoney}}<span>元</span></span>
        </div>
        <div class="item-line" v-if="item.hasOwnProperty('shouldDepositScale')">
          <span>{{type == 3 ? '初始托管率：' : '初始存管率：'}}</span>
          <div class="item-line-right">
<!--            <div class="item-progress" v-if="item.shouldDepositScale !== '&#45;&#45;'">-->
<!--              <span :style="{width: item.shouldDepositScale + '%'}"></span>-->
<!--            </div>-->
            <span v-if="item.shouldDepositScale !== '--'" class="item-progress-title">{{item.shouldDepositScale}}<span>%</span></span>
            <span v-if="item.shouldDepositScale === '--'" class="item-progress-title">{{'20%~100%'}}</span>
          </div>
        </div>
        <div class="item-line" v-if="item.hasOwnProperty('realityDepositMoney')">
          <span>{{type == 3 ? '当前托管资金：' : '当前存管资金：'}}</span>
          <span>{{item.realityDepositMoney}}<span>元</span></span>
        </div>
        <div class="item-line" v-if="item.hasOwnProperty('realityDepositScale')">
          <span>{{type == 3 ? '当前托管率：' : '当前存管率：'}}</span>
          <div class="item-line-right">
<!--            <div class="item-progress">-->
<!--              <span :style="{width: item.realityDepositScale + '%'}"></span>-->
<!--            </div>-->
            <span class="item-progress-title">{{item.realityDepositScale}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('namountOfDeposit')">
          <span>存管资金：</span>
          <span>{{item.namountOfDeposit}}<span>元</span></span>
        </div>

        <div class="item-line center" v-if="item.hasOwnProperty('nrateOfDeposit')">
          <span>存管率：</span>
          <div class="item-line-right">
<!--            <div class="item-progress">-->
<!--              <span :style="{width: item.nrateOfDeposit + '%'}"></span>-->
<!--            </div>-->
            <span class="item-progress-title">{{item.nrateOfDeposit}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('amountOfInsured1')">
          <span>{{type == 4 ? '担保金额：' : '保险金额：'}}</span>
          <span>{{item.amountOfInsured1}}<span>元</span></span>
        </div>
        <div class="item-line center" v-if="item.hasOwnProperty('rateOfUnderwrite1')">
          <span>{{type == 4 ? '担保率：' : '承保率：'}}</span>
          <div class="item-line-right">
<!--            <div class="item-progress">-->
<!--              <span :style="{width: item.rateOfUnderwrite1 + '%'}"></span>-->
<!--            </div>-->
            <span class="item-progress-title">{{item.rateOfUnderwrite1}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line" v-if="item.hasOwnProperty('amountOfInsuredN')">
          <span>{{type == 4 ? '担保金额：' : '保险金额：'}}</span>
          <span>{{item.amountOfInsuredN}}<span>元</span></span>
        </div>
        <div class="item-line center" v-if="item.hasOwnProperty('rateOfUnderwriteN')">
          <span>{{type == 4 ? '担保率：' : '承保率：'}}</span>
          <div class="item-line-right">
<!--            <div class="item-progress">-->
<!--              <span :style="{width: item.rateOfUnderwriteN + '%'}"></span>-->
<!--            </div>-->
            <span class="item-progress-title">{{item.rateOfUnderwriteN}}<span>%</span></span>
          </div>
        </div>

        <div class="item-line">
          <span>兑付比：</span>
          <span>{{item.cashScale}}%</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "homeSupervise-item",
  components:{
  },
  props:{
    itemList:{
      type: Array,
      default:()=>{
        return [{
          name:"T+0",
          tradeMoney:'0',
          depositScale:'0',
          cashScale:'0.00'
        },{
          name:"T+1",
          shouldDepositMoney:'0',
          shouldDepositScale:'0',
          realityDepositMoney:'0',
          realityDepositScale:'0',
          cashScale:'0.00'
        },{
          name:"TN-1",
          shouldDepositMoney:'0',
          shouldDepositScale:'0',
          realityDepositMoney:'0',
          realityDepositScale:'0',
          cashScale:'0.00'
        }]
      }
    },
    type:{
      type: [String,Number],
      default: 1
    }
  },
  data() {
    return {

    }
  },


  mounted() {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
.homeSupervise-item-wrap{
  &>ul{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding-bottom: 52px;
    li{
      width: 218px;
      height: 270px;
      background: url("../../assets/imgs/homeSupervise-item/item-bg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      color: #232a24;
      .item-title{
        height: 44px;
      }
      &>.item-line:first-child{
        margin-top: 42px;
      }
      .item-line{
        line-height: 1;
        margin-top: 24px;
        display: flex;
        align-items: center;
        &.center{
          margin-top: 62px;
          margin-bottom: 62px;
        }
        &>span:first-child{
          width: 120px;
          text-align: right;
        }
        &>span:last-child{
          flex: 1;
          font-size: 14px;
          color: #5e5e5e;
        }
        &>.item-line-right{
          width: calc(100% - 121px);
          display: flex;
          align-items: center;
          .item-progress{
            width: 112px;
            height: 20px;
            display: flex;
            border: 1px solid #238eff;
            background: rgba(35,142,255,0.3);

            margin-right: 7px;
            &>span{
              display: block;
              width: 0px;
              transition: all 0.3s;
              height: 100%;
              //background: url("../../assets/imgs/homeSupervise-item/progress.png") ;
            }
          }
          .item-progress-title{
            font-size: 14px;
            color: #5e5e5e;
          }
        }
        .pie-chart{
          width: 90px !important
        }
      }
    }
    &>li:nth-child(1){
      .item-title{
        background: url("../../assets/imgs/homeSupervise-item/T+0.png") no-repeat center center;
        background-size: 51px 23px;

      }
    }
    &>li:nth-child(2){
      .item-title{
        background: url("../../assets/imgs/homeSupervise-item/T+1.png") no-repeat center center;
        background-size: 49px 22px;
      }
    }
    &>li:nth-child(3){
      .item-title{
        background: url("../../assets/imgs/homeSupervise-item/T+(N-1).png") no-repeat center ;
        background-size: 99px 27px;
      }
    }
  }
}
</style>
